import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {AccessibilityModeRuntimeProps, AccessibilityModeOwnProps} from './interfaces'
import {AccessibilityMode as Presentation} from './accessibility-mode'

const mapRuntime = ({
  state,
  actions: {setShowAccessibilityMode},
}: DetailsPageAppProps): AccessibilityModeRuntimeProps => ({
  showAccessibilityMode: state.seating.showAccessibilityMode,
  plan: state.seating.plan,
  planReservations: state.seating.planReservations,
  tickets: state.tickets,
  event: state.event,
  selectedZone: state.seating.selectedZone,
  filteredCategories: state.seating.filteredCategories,
  setShowAccessibilityMode,
})

export const AccessibilityMode = connect<AccessibilityModeOwnProps, AccessibilityModeRuntimeProps>(mapRuntime)(
  Presentation,
)
export * from './interfaces'
