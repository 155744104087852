import * as React from 'react'
import {Tickets} from '../tickets'
import {TicketDescription} from '../ticket-description'
import {Plan} from '../plan'
import {Footer} from '../footer'
import {AccessibilityMode} from '../accessibility-mode'
import {SelectedPlace} from '../../../../types/seating'
import {classes} from './mobile.st.css'
import {MobileProps} from '.'

export const Mobile = ({
  selectedPlaces,
  selectedPlace,
  plan,
  onRemoveClick,
  onCheckoutClick,
  onPlaceClick,
  onAddClick,
  onCloseClick,
  showAccessibilityMode,
  setShowAccessibilityMode,
}: MobileProps) => {
  const [showTickets, setShowTickets] = React.useState(false)
  const [selectedSeatForDescription, setSelectedSeatForDescription] = React.useState<SelectedPlace>(null)

  const handleFooterButtonClick = () => {
    setShowTickets(true)
    setShowAccessibilityMode(false)
  }

  return (
    <div className={classes.container}>
      <Tickets
        showAccessibilityMode={showAccessibilityMode}
        selectedPlaces={selectedPlaces}
        showTickets={showTickets}
        onBackClick={() => setShowTickets(false)}
        onRemoveClick={onRemoveClick}
        onCheckoutClick={onCheckoutClick}
        onTicketMoreInfoClick={setSelectedSeatForDescription}
        chooseSeatMode={false}
      />
      <TicketDescription
        selectedSeatForDescription={selectedSeatForDescription}
        onClose={() => setSelectedSeatForDescription(null)}
      />
      {showAccessibilityMode ? (
        <AccessibilityMode onFooterButtonClick={handleFooterButtonClick} />
      ) : (
        <>
          <Plan selectedSeat={selectedPlace} selectedSeats={selectedPlaces} plan={plan} onPlaceClick={onPlaceClick} />
          <Footer
            withoutBreakdown={true}
            selectedPlace={selectedPlace}
            onAddClick={onAddClick}
            onCloseClick={onCloseClick}
            onCheckoutClick={() => setShowTickets(true)}
          />
        </>
      )}
    </div>
  )
}
