import * as React from 'react'
import {TicketsListContainer} from '../../tickets-list-container'
import {Ticket} from '../../ticket'
import {SelectedPlace} from '../../../../../types/seating'
import {TicketsListProps} from '.'

export const TicketsList = ({showAccessibilityMode, places, selectedPlaces, handlePlaceSelect}: TicketsListProps) => {
  const handlePlaceClick = (clickedPlace: SelectedPlace) => {
    handlePlaceSelect({place: clickedPlace})
  }

  return (
    <TicketsListContainer>
      {places.map((place, index) => {
        const selected = selectedPlaces.some(selectedPlace => selectedPlace.id === place.id)

        return (
          <Ticket
            showAccessibilityMode={showAccessibilityMode}
            key={place.id}
            lastTicket={index === places.length - 1}
            place={place}
            onClick={clickedPlace => handlePlaceClick(clickedPlace)}
            selected={selected}
          />
        )
      })}
    </TicketsListContainer>
  )
}
