import * as React from 'react'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import {addCircularTabFocus, focusElement, hookToAttributeSelector} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {SlideInModal} from '../../slide-in-modal'
import {SelectedPlace} from '../../../../types/seating'
import {getPlaceWithTicketInfo} from '../../../../selectors/seating'
import {Header} from './header'
import {Filters} from './filters'
import {classes} from './accessibility-mode.st.css'
import {Footer} from './footer'
import {TicketsCountInfo} from './tickets-count-info'
import {TicketsList} from './tickets-list'
import {AccessibilityModeProps} from '.'

export const AccessibilityMode = ({
  showAccessibilityMode,
  tickets,
  plan,
  event,
  selectedZone,
  filteredCategories,
  setShowAccessibilityMode,
  onFooterButtonClick,
  planReservations,
}: AccessibilityModeProps) => {
  const {isMobile} = useEnvironment()
  const {t} = useTranslation()

  React.useEffect(() => {
    focusElement({selector: hookToAttributeSelector(DH.BACK), canFocus: true})
    addCircularTabFocus(hookToAttributeSelector(DH.CLOSE_BUTTON), hookToAttributeSelector(DH.ACCESSIBILITY_MODE_END))
  }, [showAccessibilityMode])

  const places: SelectedPlace[] = filteredCategories
    .flatMap(category =>
      category.places
        .filter(place => {
          const occupied = place.capacity - planReservations.reservations[place.id].occupied === 0
          return !occupied && place.capacity > 0 && (selectedZone ? place.id.split('-')[1] === selectedZone : true)
        })
        .map(place => getPlaceWithTicketInfo({plan, tickets, event, place, categoryId: category.id, t})),
    )
    .sort((a, b) => b.capacity - a.capacity)

  const placesCount = places.length

  if (isMobile) {
    return (
      <div className={classes.mobileContainer}>
        <Filters />
        <TicketsCountInfo placesCount={placesCount} />
        <TicketsList places={places} />
        <Footer onButtonClick={onFooterButtonClick} />
      </div>
    )
  }

  return (
    <SlideInModal
      show={showAccessibilityMode}
      backButtonText={t('seatings_closeTicketList')}
      onBackClick={() => setShowAccessibilityMode(false)}
    >
      <Header />
      <Filters />
      <TicketsCountInfo placesCount={placesCount} />
      <TicketsList places={places} />
      <Footer />
    </SlideInModal>
  )
}
