import {Place} from '@wix/ambassador-seating-v1-seating-plan/types'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import {Modal} from '../modal'
import {getPlaceWithTicketInfo} from '../../../selectors/seating'
import {SelectedPlace} from '../../../types/seating'
import {Loader} from './loader'
import {Mobile} from './mobile'
import {Desktop} from './desktop'
import {classes} from './seating-plan.st.css'
import {SeatingPlanProps} from '.'

export const SeatingPlan = ({
  plan,
  tickets,
  handlePlaceSelect,
  checkout,
  loading,
  event,
  selectedPlaces,
  showAccessibilityMode,
  chooseSeatMode,
  handlePlaceRemove,
  setShowAccessibilityMode,
}: SeatingPlanProps) => {
  const {t} = useTranslation()
  const {isMobile: mobile} = useEnvironment()
  const [selectedPlace, setSelectedPlace] = useState<SelectedPlace>(null)
  const desktop = !mobile

  useEffect(() => {
    if (chooseSeatMode) {
      window.parent.postMessage({selectedPlaces}, 'https://manage.wix.com')
    }
  }, [selectedPlaces])

  const resetSelectedPlace = useCallback(() => setSelectedPlace(null), [setSelectedPlace])

  const handleAddClick = useCallback(
    (amount: number = 1) => {
      handlePlaceSelect({
        place: selectedPlace,
        count: amount,
      })
      resetSelectedPlace()
    },
    [selectedPlace],
  )

  const handleCheckout = useCallback(() => checkout(), [checkout])

  const onPlaceClick = useCallback(
    (place: Place, categoryId: number) => {
      if (desktop || showAccessibilityMode || selectedPlaces.find(({id}) => id === place.id)) {
        handlePlaceSelect({place: getPlaceWithTicketInfo({plan, event, place, categoryId, t, tickets})})
      } else {
        selectedPlace?.id === place.id
          ? resetSelectedPlace()
          : setSelectedPlace(getPlaceWithTicketInfo({plan, event, place, categoryId, t, tickets}))
      }
    },
    [showAccessibilityMode, setSelectedPlace, selectedPlace, selectedPlaces],
  )

  const onRemoveCLick = useCallback(
    (placeId: string, ticketId: string) => {
      handlePlaceRemove({placeId, ticketId})
    },
    [handlePlaceRemove],
  )

  const mobileTabs = useMemo(
    () => [{title: t('seatingMapModal_mapTab_title')}, {title: t('seatingMapModal_listTab_title')}],
    [t],
  )

  const onTabClick = useCallback(() => setShowAccessibilityMode(!showAccessibilityMode), [
    setShowAccessibilityMode,
    showAccessibilityMode,
  ])

  return (
    <Modal
      className={classNames(
        desktop && !chooseSeatMode && classes.desktopModal,
        chooseSeatMode && classes.hideCloseButton,
      )}
      closeButtonAsButton={desktop}
      dataHook={DH.SEATINGS_MODAL}
      title={mobile ? t('seatingMapModal_title') : undefined}
      withoutPadding={desktop}
      tabs={mobile ? mobileTabs : undefined}
      activeTabIndex={showAccessibilityMode ? 1 : 0}
      onTabClick={onTabClick}
    >
      {loading ? <Loader /> : null}
      {mobile ? (
        <Mobile
          selectedPlace={selectedPlace}
          selectedPlaces={selectedPlaces}
          plan={plan}
          onRemoveClick={onRemoveCLick}
          onCheckoutClick={handleCheckout}
          onPlaceClick={onPlaceClick}
          onCloseClick={resetSelectedPlace}
          onAddClick={handleAddClick}
        />
      ) : (
        <Desktop
          selectedPlace={selectedPlace}
          selectedPlaces={selectedPlaces}
          plan={plan}
          onPlaceClick={onPlaceClick}
          onRemoveClick={onRemoveCLick}
          onCheckoutClick={handleCheckout}
          showAccessibilityMode={showAccessibilityMode}
        />
      )}
    </Modal>
  )
}
